import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { standardWrapper } from "../../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import TracedChameleon from "../../Chameleons/TracedChameleon"
import SvgTracedChameleon from "../../Chameleons/SvgTracedChameleon"

gsap.registerPlugin(ScrollTrigger)

const InfographicSection = styled.div`
  position: relative;

  .wrapper {
    ${standardWrapper};
  }

  .infoWrapper {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;

    @media (min-width: 768px) {
      max-width: 50rem;
    }

    @media (min-width: 1025px) {
      max-width: 75rem;
    }
  }

  .chameleonGrapicRight {
    position: absolute;
    top: -37.5rem;
    right: 5vw;
    width: 34.49rem;
    height: 25.55rem;

    @media (min-width: 768px) {
      top: -10rem;
    }

    @media (min-width: 1025px) {
      top: -25rem;
    }

    svg {
      width: 100%;
    }
  }
`

const Infographic = ({ infographic }) => {
  const infograph = useRef(null)
  const chameleon = useRef(null)

  useEffect(() => {
    const triggerElement = document.querySelector("#infoProcess")
    const chameleonSvg = chameleon.current.querySelector(".chamePath")
    gsap.set(infograph.current, {
      x: -100,
      scale: 0.5,
      rotation: -90,
      autoAlpha: 0,
    })

    gsap.set(chameleonSvg, {
      strokeDasharray: chameleonSvg.getTotalLength(),
      strokeDashoffset: chameleonSvg.getTotalLength(),
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 65%",
          toggleActions: "play none none none",
        },
      })
      .to(infograph.current, {
        x: 0,
        autoAlpha: 1,
        scale: 1,
        rotation: 0,
        duration: 0.5,
      })
      .to(chameleonSvg, { strokeDashoffset: 0, duration: 4 }, "+=0.4")
  }, [])

  return (
    <InfographicSection id="infoProcess">
      <div className="wrapper">
        <div ref={infograph} className="infoWrapper">
          <Img
            fluid={
              infographic.acf._kon_infographic_section.localFile.childImageSharp
                .fluid
            }
            alt={infographic.acf._kon_infographic_section.alt_text}
          />
        </div>
      </div>
      <div ref={chameleon} className="chameleonGrapic chameleonGrapicRight">
        <SvgTracedChameleon />
      </div>
    </InfographicSection>
  )
}

export default Infographic
