import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { medWrapper, H1DarkGrey, B1DarkGrey } from "../../../styles/helpers"

import SvgTracedChameleonRev from "../../Chameleons/SvgTracedChameleonRev"

gsap.registerPlugin(ScrollTrigger)

const AboutIntroSection = styled.section`
  position: relative;
  margin: 10rem auto;
  padding: 15rem 0 5rem;

  @media (min-width: 768px) {
    margin: 5rem auto 5rem;
    padding: 0;
  }

  .wrapper {
    ${medWrapper};
    align-items: center;
    flex-direction: row-reverse;
  }

  .content {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(66.66%);
      text-align: left;
    }

    @media (min-width: 1025px) {
      width: calc(75% - 10rem);
      margin-right: 10rem;
    }

    h2 {
      ${H1DarkGrey};

      span {
        display: inline-block;
      }
    }

    p {
      ${B1DarkGrey};
    }
  }

  .imageSpacer {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(33.33%);
    }

    @media (min-width: 1025px) {
      width: calc(25%);
    }
  }

  .chameleonGrapicLeft {
    position: absolute;
    bottom: 5rem;
    left: -5vw;
    width: calc(50.49rem / 1.75);
    height: calc(38.19rem / 1.75);

    @media (min-width: 768px) {
      bottom: -7.5rem;
      width: calc(50.49rem / 1.25);
      height: calc(38.19rem / 1.25);
    }

    @media (min-width: 1025px) {
      top: -5rem;
      bottom: auto;
      width: calc(50.49rem);
      height: calc(38.19rem);
    }

    svg {
      width: 100%;
    }
  }
`

const AboutIntro = ({ aboutUs }) => {
  const chameleonRev = useRef(null)

  useEffect(() => {
    const triggerElement = document.querySelector("#introSection")
    const titleLetter = [...triggerElement.querySelectorAll(".content h2 span")]
    const content = [...triggerElement.querySelectorAll(".aboutIntroContent")]
    const chameleonSvgRev = chameleonRev.current.querySelector(".chamePathRev")

    gsap.set(titleLetter, { autoAlpha: 0, y: -35 })
    gsap.set(content, { autoAlpha: 0, y: 100 })
    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to(titleLetter, {
        autoAlpha: 1,
        y: 0,
        duration: 0.75,
        ease: "power4.out",
        stagger: {
          each: 0.1,
        },
      })
      .to(content, { autoAlpha: 1, y: 0 }, "-=0.5")

    gsap.set(chameleonSvgRev, {
      strokeDasharray: chameleonSvgRev.getTotalLength(),
      strokeDashoffset: chameleonSvgRev.getTotalLength(),
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to(chameleonSvgRev, { strokeDashoffset: 0, duration: 3 }, "-=1")
  }, [])

  const newTitle = aboutUs.acf._kona_abusin_title.split("")

  return (
    <AboutIntroSection id="introSection">
      <div className="wrapper">
        <div className="content">
          <div>
            <h2>
              {newTitle.map((letter, index) => {
                return letter !== " " ? <span key={index}>{letter}</span> : " "
              })}
            </h2>
          </div>
          <div
            className="aboutIntroContent"
            dangerouslySetInnerHTML={{
              __html: aboutUs.acf._kona_abusin_content,
            }}
          />
        </div>
        <div className="imageSpacer" />
      </div>
      <div
        ref={chameleonRev}
        className="image chameleonGrapic chameleonGrapicLeft"
      >
        <SvgTracedChameleonRev />
      </div>
    </AboutIntroSection>
  )
}

export default AboutIntro
