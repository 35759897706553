import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Hero from "../components/TemplateComponets/About/Hero"
import AboutIntro from "../components/TemplateComponets/About/AboutIntro"
import Infographic from "../components/TemplateComponets/About/Infographic"
import BottomHero from "../components/TemplateComponets/About/BottomHero"

const about = props => {
  const { seoInfo, hero, aboutUs, infographic, botHero } = props.data
  const location = props.location
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <Hero hero={hero} />
      <AboutIntro aboutUs={aboutUs} />
      <Infographic infographic={infographic} />
      <BottomHero botHero={botHero} />
    </Layout>
  )
}

export const aboutQuery = graphql`
  query aboutPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }
    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_serher_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    aboutUs: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kona_abusin_title
        _kona_abusin_content
        _kona_abusin_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    infographic: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_infographic_section {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    botHero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_serbother_bottom_hero {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _kon_serbother_button_text
        _kon_serbother_button_link
      }
    }
  }
`

export default about
